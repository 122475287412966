body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e2e2e2 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.m-2 {
  margin: 0.875em;
}

.my-2 {
  margin-top: 0.875em;
  margin-bottom: 0.875em;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-2 {
  margin-left: 0.875em;
  margin-right: 0.875em;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.pt-80 {
  padding-top: 80px !important;
}

.py-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.br-50 {
  border-radius: 50% !important;
}

.w-200 {
  width: 200px !important;
}

.w-240 {
  width: 240px !important;
}

.w-400 {
  width: 400px !important;
}

.h-100vh {
  height: 100vh !important;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}