.viModal {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: auto !important;
}

.viReadyButton {
    opacity: 1 !important;
}
